import { PlusIcon } from "@heroicons/react/24/outline";
import { type Table, flexRender } from "@tanstack/react-table";

import { useVirtualizer } from "@tanstack/react-virtual";
import Link from "next/link";

import useFetchWhenDone from "~/hooks/useFetchWhenDone";
import { useCampaign } from "~/providers/CampaignProvider";
import SkeletonRow from "./SkeletonRow";
import useVirtualPadding from "~/hooks/useVirtualPadding";
import {
  getScrollElement,
  stickToRight,
} from "~/components/campaigns/MainPage/SharedTable/utils";

import { useCampaigns } from "~/providers/CampaignsProvider";

const Body = <T,>({
  table,
  hasNextUserPage,
  isFetchingNextUsersPage,
  handleGetNextUserPage,
  hideFinalRow,
  hideFinalColumn,
}: {
  table: Table<T>;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
  handleGetNextUserPage: () => void;
  hideFinalRow?: boolean;
  hideFinalColumn?: boolean;
}) => {
  const campaign = useCampaign();

  const { rows } = table.getRowModel();
  const rowCount = hasNextUserPage ? rows.length + 1 : rows.length;
  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtualizer({
    getScrollElement,
    count: rowCount,
    estimateSize: () => 43,
    overscan: 20,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  useFetchWhenDone(
    handleGetNextUserPage,
    virtualRows,
    rowCount,
    isFetchingNextUsersPage,
    hasNextUserPage
  );

  const [paddingTop, paddingBottom] = useVirtualPadding(rowVirtualizer);
  const { campaigns } = useCampaigns();
  const backuppedCampaign = campaign ?? campaigns[0];

  return (
    <tbody>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}

      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const row = rows[virtualRow.index];
        const isLoaderRow = virtualRow.index > rows.length - 1;
        const isLoading = isLoaderRow && isFetchingNextUsersPage;
        const isLastRow = isLoaderRow && !isFetchingNextUsersPage;
        if (isLoading) return <SkeletonRow numbering={virtualRow.index + 1} />;

        if (isLastRow) {
          return null;
        }

        return (
          <tr className="z-30 border-y" key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
              return (
                <td
                  className={`border-r ${
                    index > 0 ? "z-10 border-gray-200" : stickToRight
                  }`}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
            {!hideFinalColumn && <td className="z-10 border-gray-200"></td>}
          </tr>
        );
      })}
      {backuppedCampaign &&
        !hideFinalRow &&
        rows[0]?.getVisibleCells().map((_e, index) => {
          return (
            <td className="sticky left-0 z-20  bg-gray-100 p-3">
              <Link
                href={
                  backuppedCampaign
                    ? `/campaigns/${backuppedCampaign?.id}/add-contact`
                    : "/campaign/new"
                }
              >
                <PlusIcon
                  className={`h-6 w-6 ${
                    !index ? "text-gray-400" : "text-transparent"
                  }`}
                />
              </Link>
            </td>
          );
        })}

      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </tbody>
  );
};

export default Body;
