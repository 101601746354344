import { Dialog } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState, type Dispatch, type SetStateAction } from "react";
import Button from "~/components/base/Button";
import DropDown from "~/components/base/DropDown";
import Input from "~/components/base/Input";
import { useCampaign, useCampaignRefetch } from "~/providers/CampaignProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api } from "~/utils/api";

const AddCustomFieldModal = ({
  toggleHook,
  targetType,
}: {
  toggleHook: [boolean, Dispatch<SetStateAction<boolean>>];
  targetType: string;
}) => {
  const [showDialog, setShowDialog] = toggleHook;
  const campaignRefetch = useCampaignRefetch();
  const onSuccess = () => {
    campaignRefetch().catch(console.error);
    setShowDialog(false);
  };
  const { mutate } = api.campaign.campaignAddCustomField.useMutation({
    onSuccess,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = useState("text");
  const [warning, setWarning] = useState("");
  const [value, setValue] = useState("");
  const { activeTeamAccount } = useTeamAccount();
  const campaign = useCampaign();

  const items = [
    { name: "Text", value: "text" },
    { name: "Number", value: "number" },
    { name: "Date", value: "date" },
    { name: "Boolean", value: "boolean" },
  ];
  const handleSave = () => {
    if (!value) {
      setWarning("Please enter a field name");
      return;
    }
    setWarning("");
    mutate({
      targetType,
      type,
      key: value,
      teamAccountId: activeTeamAccount?.id,
      campaignId: campaign?.id ?? "",
    });
  };
  return (
    <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black/40">
        <div className="flex min-h-full items-center justify-center px-8 py-4 text-center">
          <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
              as="h3"
              className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 "
            >
              <span className="py-2 text-2xl">Add Custom Field</span>
              <Input
                value={value}
                setValue={setValue}
                placeholder="Field Name"
              />
              <div className="relative flex w-full items-start gap-2">
                <span>Type:</span>
                <button
                  onClick={() => {
                    setShowDropdown(true);
                  }}
                  className="flex items-center gap-2"
                >
                  <span>{type}</span>
                  <ChevronDownIcon className="h-4 w-4" />
                </button>
                <DropDown
                  className="top-6 bg-offwhite"
                  filterOpen={showDropdown}
                  closeDropDown={() => {
                    setShowDropdown(false);
                  }}
                  selectItem={(value) => {
                    setType(value);
                  }}
                  items={items}
                />
              </div>
              {warning && <span className="text-red-500">{warning}</span>}
              <Button onClick={handleSave} className="w-full">
                Save
              </Button>
            </Dialog.Title>

            <div className="flex flex-col gap-4 py-4 "></div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default AddCustomFieldModal;
