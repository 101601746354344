import type { Virtualizer } from "@tanstack/react-virtual";

const useVirtualPadding = (
  rowVirtualizer: Virtualizer<Element, Element>
): [number, number] => {
  const items = rowVirtualizer.getVirtualItems();
  return items.length > 0
    ? [
        Math.max(0, items[0].start - rowVirtualizer.options.scrollMargin),
        Math.max(
          0,
          rowVirtualizer.getTotalSize() - items[items.length - 1].end
        ),
      ]
    : [0, 0];
};
export default useVirtualPadding;
