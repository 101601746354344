import { Skeleton } from "../SharedTable/utils";
const SkeletonRow = ({ numbering }: { numbering: number }) => {
  return (
    <tr key="loading" className="z-30 border-y">
      <td className="sticky left-0 top-0 z-20 overflow-hidden border-r border-r border-gray-100 bg-gray-50 text-left">
        <div className="flex h-max items-stretch py-2  text-left ">
          <div className=" px-3 text-right text-gray-400/50">{numbering}</div>
          <div className="">
            <div className="flex  animate-pulse px-2">
              <div className="h-6  rounded-md bg-gray-200/50"></div>
            </div>
          </div>
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-r border-gray-200">
        <div className=" truncate px-2 text-sm">
          <Skeleton />
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <div className=" truncate px-2 text-sm">
          <Skeleton />
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton />
      </td>
      <td className="z-10 border-gray-200 border-t-red-500"></td>
    </tr>
  );
};
export default SkeletonRow;
